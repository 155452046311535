@keyframes slideInFromBelow {
  0% {
    transform: translateY(20%) scale(0.75);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.viewportBelow {
  animation-iteration-count: 1;
  animation: 1s ease-out 0s 1 slideInFromBelow;
}

.viewportLeft {
  animation-iteration-count: 1;
  animation: 1s ease-out 0s 0.7 slideInFromBelow;
}

.viewportRight {
  animation-iteration-count: 1;
  animation: 1s ease-out 0s 0.7 slideInFromBelow;
}

@media only screen and (min-width: 768px) {
  .viewportLeft {
    animation: 1s ease-out 0s 0.7 slideInFromLeft;
    animation-iteration-count: 1;
  }
}

@media only screen and (min-width: 768px) {
  .viewportRight {
    animation: 1s ease-out 0s 0.7 slideInFromRight;
    animation-iteration-count: 1;
  }
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1.3s linear infinite; /* Safari */
  animation: spin 1.3s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
